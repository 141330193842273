import { lazy, Suspense } from 'react';
import { Loader, OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Controllers, VRButton, XR } from '@react-three/xr';
import { useMediaQuery } from 'react-responsive';
import { styles } from './AppStyles';
import MobileWarning from 'components/MobileWarning';
const ArtGallery = lazy(() => import('./ArtGallery'));

function App() {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <>
      {isMobile ? (
        <MobileWarning />
      ) : (
        <>
          <Suspense fallback={null}>
            <div style={styles.Instructions}>
              There are two easy ways to view the gallery in Virtual Reality:
              <br />
              1️⃣ visit gallery.nxdec.io in the Meta Quest browser
              <br />
              2️⃣ use Chrome or Edge, connect your headset and press Enter VR
            </div>
            <VRButton style={styles.VRButton} onClick={() => console.log('clicked me!')} />
            <Canvas shadows>
              <XR>
                <OrbitControls />
                <Controllers />
                <ArtGallery />
              </XR>
            </Canvas>
          </Suspense>
          <Loader dataInterpolation={p => `Loading VR Art Gallery ${p.toFixed(2)}% \nPlease wait..`} />
        </>
      )}
    </>
  );
}

export default App;
